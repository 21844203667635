<template>
<div>
  <Row>
    <Col span="24">
      <Form :model="formItem" :label-width="100" ref="userForm">

        <FormItem label="名称" prop="user_nick" >
          <Input v-model="formItem.user_nick" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="账号" prop="user_name" >
          <Input v-model="formItem.user_name" style="width: 600px" ></Input>
        </FormItem>

        <FormItem label="密码" prop="user_pwd" >
          <Input v-model="formItem.user_pwd" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="状态" prop="user_level" >
          <Slider style="width: 600px" :step="5" :marks="marks" v-model="formItem.user_level" :min="0" :max="50"></Slider>
        </FormItem>

        <FormItem label="角色" prop="user_role" >
          <treeselect @deselect="handleDeselect" :value-consists-of="'LEAF_PRIORITY'" v-model="formItem.roles" :options="roles" style="width: 600px" :multiple="true"/>
        </FormItem>

        <FormItem>
          <Button size="large" type="primary" @click="submit()">确定</Button>
        </FormItem>

      </Form>
    </Col>
  </Row>
</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import api from '../../api/setup';
export default {
  name: "UserDetail",
  components: { Treeselect },
  data: function(){
    return {
      formItem: {
        user_id: '',
        user_pwd: '',
        user_nick: '',
        user_name: '',
        user_level: 0,
        roles: [],
        user_city: []
      },
      roles: [],
      marks: {
        5: {
          style: {
            color: '#19be6b'
          },
          label: this.$createElement('strong', '正常')
        },
        45: {
          style: {
            color: '#2b85e4'
          },
          label: this.$createElement('strong', '管理员')
        },
        0: {
          style: {
            color: '#e13d13'
          },
          label: this.$createElement('strong', '禁用')
        }
      },
      city: []
    }
  },
  methods: {
    submit: function(){
      this.$http.patch(api.modUser, this.formItem).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功');
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    handleDeselect: function(node, i){
      console.log(node, i)
    }
  },
  mounted() {
    this.$emit('lastPage', '/setup/users')
    const id = this.$route.query.id;
    this.$http.get(api.getRoles).then(response => {
      if(response.data.success) {
        this.roles.push(...response.data.res);
        this.$http.get(api.userDetail, {params:{id:id}}).then(res => {
          if(res.data.success){
            this.formItem = res.data.res;
          } else {
            this.$Message.error(res.data.err);
            console.log(res)
          }
        })
      } else {
        this.$Message.error(response.data.err);
        console.log(response)
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>